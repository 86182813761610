<template>
    <v-content>
        <v-breadcrumbs :items="breadcrumbs" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-container>
                 <v-row class="float-right">
                     <v-file-input dense label="Import File" outlined v-model="formdata.import"></v-file-input>
                     <v-btn @click.stop="onImport" class="primary float-right mx-4">
                         <v-icon>publish</v-icon>
                     </v-btn>
                     <v-btn @click.stop="onDownload" class="success float-right mx-4">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-row>
             </v-container>-->
            <vue-good-table :columns="columns"
                            :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                            :rows="rows"
                            :totalRows="customerData.totalRecords"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'">
                        <v-chip small dark color="green" class="ml-1" @click="showUpdateModal(props.row, 'Passed')">{{$t('passed')}}</v-chip>
                        <v-chip small dark color="yellow darken-4" class="ml-1"
                                @click="showUpdateModal(props.row, 'Left')">{{$t('left')}}</v-chip>
                        <v-chip small dark color="#B72626" class="ml-1" @click="showUpdateModal(props.row, 'Failed')">{{$t('failed')}}</v-chip>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog v-model="dialogUpdate" max-width="500px">
                <v-card v-if="dialogUpdate">
                    <v-card-title class="headline">{{$t('update')}}?</v-card-title>
                    <v-card-text>
                        <h3>{{$t('please_fill_the_form_below_before_submitting')}} </h3>
                        <v-card-subtitle>{{$t('passed_year')}}: {{updateFormData.passedYear}}</v-card-subtitle>
                        <v-date-picker v-model="updateFormData.passedYear"></v-date-picker>
                        <v-text-field  type="number" :label="$t('total_amount')" v-model="updateFormData.totalAmount"  outlined dense></v-text-field>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="rgb(157,25,25)" text @click="dialogueClose">{{$t('cancel')}}</v-btn>
                        <v-btn color="green darken-1" text @click="updateItem" :disabled="!updateFormData.passedYear">
                            {{$t('submit')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar :timeout="snackbar.timeout"
                        color="success"
                        top
                        v-model="snackbar.snackbar">
                {{ callbackResponse.message }}
                <v-btn @click="snackbar.snackbar = false"
                       dark
                       text>
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AcademicList",
        components: {},
        computed: {
            getType() {
                return this.$route.params.slug === 'AcademicsList' ? 'academic' : (this.$route.params.slug === 'TrainingsList') ? 'training' : 'internship'
            },
            breadcrumbPrevCaption() {
                return this.$route.params.slug === 'AcademicsList' ? 'Academic Information' : (this.$route.params.slug === 'TrainingsList') ? 'Training Information' : 'Internship Information'
            },
            breadcrumbs() {
                return [
                    {
                        text: 'Dashboard',
                        disabled: false,
                        to: '/AcademicDashboard',
                        exact: true

                    },
                    {
                        text: this.breadcrumbPrevCaption,
                        disabled: false,
                        to: `/${this.$route.params.slug}`,
                        exact: false

                    },
                    {
                        text: 'Pending List',
                        disabled: true,
                    },
                ]
            }
        },
        methods: {
            showUpdateModal({studentID}, status) {
                this.dialogUpdate = true;
                this.updateFormData.url = "Academic/UpdatePendingStudentDetailAsync"
                this.updateFormData.studentID = studentID
                this.updateFormData.passedStatus = status
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.dialogUpdate = false
                this.updateFormData = {}
                this.loadItems()
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Type: this.getType
                };
                axios.post('Academic/GetPendingStudentListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    })

            },
            async updateItem() {
                try {
                    const {data} = await axios.post(this.updateFormData.url, {
                        studentID: this.updateFormData.studentID,
                        passedYear: this.updateFormData.passedYear,
                        totalAmount: parseInt(this.updateFormData.totalAmount),
                        passedStatus: this.updateFormData.passedStatus,
                    })
                    this.snackbar.snackbar = true
                    this.callbackResponse.message = "Updated Successfully"
                    this.dialogueClose()
                    if (data.success) {
                        this.callbackResponse.message = "Updated Successfully"
                        this.snackbar.color = 'success';
                    } else {
                        this.callbackResponse.message = data.errors[0]
                        this.snackbar.color = 'error'
                    }
                } catch (e) {
                    console.log('e', e)

                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
        },
        mounted() {
            this.loadItems()
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'name',
                        field: "name"
                    },
                    /* {
                         label: "faculty",
                         field: "Faculty"
                     },*/
                    {
                        label: 'address',
                        field: 'address',
                    },
                    {
                        label: 'number',
                        field: 'mobileNumber',
                    },
                    {
                        label: 'email',
                        field: 'email',
                    },
                    {
                        label: 'amount',
                        field: 'amount',
                    },
                    /* {
                         label: 'Total amount',
                         field: 'totalAmount',
                     },
                     {
                         label: 'Passed Year',
                         field: 'passedYear',
                     },*/
                    {
                        label: 'status',
                        field: 'passedStasus',
                    },
                    {label: 'actions', field: "actions"}
                ],
                rows: [],
                dialogDelete: false,
                deleteFormData: [],
                dialogUpdate: false,
                updateFormData: {},
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "studentID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dynamicComponent: {
                        current: 'AcademicForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
